import React, { useState, useEffect } from "react";
import Axios from "axios";
import Styles from "../../Styles/scoreboard.module.scss";
import Headlogo from "../../Assets/Logo/footerlogo.webp";
import VideoUrl from "../../Assets/Leaderboard/BgL.mp4";
const Grsscoreboard = () => {
  const [data, setData] = useState([]);

  const Api = `${process.env.REACT_APP_API_URL}/scoreboard/game/671254864f5c3479ebf40701`;

  const getdata = async () => {
    try {
      let response = await Axios.get(Api);

      setData(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getdata();
    const pollInterval = setInterval(() => {
      getdata();
    }, 5000);

    return () => {
      clearInterval(pollInterval);
    };
  }, []);
  const sortedData = [...data];

  const latestData = sortedData.slice(0, 10);

  return (
    <div className={Styles.leadermaincontainer}>
       <video
        className={Styles.videocontainer}
        controls={false}
        width="100%"
        src={VideoUrl}
        poster=""
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        controlsList="nodownload"
      />
      <div className={Styles.leaderfixed}>
        <div className={Styles.leaderimgmain}>
          <img src={Headlogo} alt="headimg" />
        </div>
        <div className={Styles.leaderlogocontainer}>
          <h1>
            <br />
            <span id={Styles.headparaarabic}>محاكي جي ار</span> <br />
            GR SIMULATOR
          </h1>
        </div>
        <div className={Styles.leadermainheadingcontainer}>
          <div className={Styles.leadermainheadingarabicmain}>
            <h4>Recent players</h4>{" "}
            <h4 id={Styles.headarabic}>اللاعبين السابقين</h4>{" "}
          </div>
        </div>

        <div className={Styles.leadersubheadingcontainer}>
          <div>
            <p>NAME</p>
          </div>

          <div>
            <p>SCORE</p>
          </div>
        </div>

        <div className={Styles.mainscoreboardcontainer}>
          {latestData.map((item, index) => (
            <div key={item.uniqueCode} className={Styles.mainscoreboardrow}>
              <div className={Styles.containerone}>{index + 1}</div>
              <div className={Styles.separator}></div>
              <div className={Styles.containertwo}>{item.user.name}</div>
              {/* <div className={Styles.separator}></div> */}
              <div className={Styles.containerthree}>{item.score}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Grsscoreboard;
