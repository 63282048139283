import React from "react";
import { useLocation } from "react-router-dom";
import Styles from "../../Styles/viewuser.module.scss";
import Headlogo from "../../Assets/Logo/headlogo.webp";
import Footerlogo from "../../Assets/Logo/footerlogo.webp";
import Idcard from "../../Assets/Titlecards/id.png";
import Loading from "../Loading";

const Viewuserid = () => {
  const { state } = useLocation();
  const { user } = state || {};

  if (!user) {
    return <Loading content={"No user data found"} />;
  }

  return (
    <div className={Styles.useridcolorbg}>
      <div key={user._id} className={Styles.useridsection}>
        <div className={Styles.useridimgmain}>
          <img src={Headlogo} alt="headimg" />
        </div>

        {/* title user id */}
        <div className={Styles.useridtitlecardmain}>
          <div className={Styles.useridtitlechild}>
            <div className={Styles.usertitlehead}>
              {/* <h1>{user.name}</h1> */}
              {user.name
                .split("\n")
                .slice(0, 2)
                .map((para, index) => (
                  <h1 key={index}>
                    {para.length > 15 ? `${para.slice(0, 15)}...` : para}
                  </h1>
                ))}
            </div>

            <img src={Idcard} />
            <h2>{user.code}</h2>
          </div>
        </div>

        {/* title user id ends */}
        <div className={Styles.footerlogo}>
          <img src={Footerlogo} alt="Footerlogo" />
        </div>
      </div>
    </div>
  );
};

export default Viewuserid;
