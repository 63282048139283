import React, { useState } from "react";
import Styles from "../../Styles/entrydata.module.scss";
import Submitbtn from "../../Assets/Form/submit.webp";
import Scoremsgar from "../../Assets/Form/scoremsgar.webp";
import Scoremsgen from "../../Assets/Form/scoremsgen.webp";
import Scorear from "../../Assets/Form/scorear.webp";
import Score from "../../Assets/Form/score.webp";
import Logo from "../../Assets/Form/logo.webp";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Vrsscoreboarddata() {
  const [inputValue, setInputValue] = useState("");
  const [userName, setUserName] = useState("");
  const [userScore, setUserScore] = useState("");
  const [toastId, setToastId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for button disabling
  const yourToken = localStorage.getItem("adminToken");

  const showToast = (message, type = "error", callback) => {
    const options = {
      className: "",
      onClose: callback,
    };
    if (type === "success") {
      options.className = "toastSuccess";
    }
    if (!toast.isActive(toastId)) {
      const id = toast[type](message, options);
      setToastId(id);
    }
  };

  const handleInputChange = async (event) => {
    const value = event.target.value;
    setInputValue(value);

    if (value.length === 4) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/scoreboard/user/game/6712543f4f5c3479ebf406e9/${value}`
        );
        if (response.data && response.data.results) {
          const userData = response.data.results;
          setUserName(userData.user.name || "");
          setUserScore(userData.scoreboard ? userData.scoreboard.score : 0);
        } else {
          showToast(response.data.message || "User not found", "error");
          setUserName("");
          setUserScore("");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        showToast(error.response.data.message || error.message);
        setUserName("");
        setUserScore("");
      }
    } else {
      setUserName("");
      setUserScore("");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Disable button while submitting

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/scoreboard`,
        {
          user: inputValue,
          score: userScore,
          game: "6712543f4f5c3479ebf406e9",
        },
        {
          headers: {
            Authorization: `Bearer ${yourToken}`,
          },
        }
      );
      if (response.status) {
        showToast("Score submitted successfully", "success", () => {
          setInputValue("");
          setUserName("");
          setUserScore("");
        });
      } else {
        showToast(response.data.message || "Failed to store score", "error");
      }
    } catch (error) {
      console.error("Error storing score:", error);
      showToast("Please enter all the fields!", "error");
    } finally {
      setIsSubmitting(false); // Re-enable button after submission
    }
  };

  return (
    <div className={Styles.dataentryparentcontainer}>
      <ToastContainer />
      <div className={Styles.dataentrychildmain}>
        <form autoComplete="off" onSubmit={handleFormSubmit}>
          <div className={Styles.dataformchild}>
            <div className={Styles.inputfirstchild}>
              <img style={{ marginLeft: "6em" }} src={Scoremsgar} />
              <input
                className={Styles.inputcode}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
              />
              <img src={Scoremsgen} />
            </div>
            <br />
            <br />
            <div className={Styles.inputsecondchild}>
              <input
                className={Styles.inputcode}
                type="text"
                value={userName}
                readOnly
              />
            </div>
            <br />
            <br />
            <div className={Styles.inputthridchild}>
              <img style={{ marginLeft: "6em" }} src={Scorear} />
              <input
                className={Styles.inputcode}
                type="text"
                value={userScore}
                onChange={(e) => setUserScore(e.target.value)}
              />
              <img src={Score} />
            </div>
          </div>

          <div className={Styles.datalogoarea}>
            <div className={Styles.logocontainer}>
              <img src={Logo} alt="logo" />
            </div>

            <div className={Styles.logocontainertext}>
              <h1>VR SIMULATOR</h1>
            </div>

            <button
              className={Styles.submitbtn}
              type="submit"
              disabled={isSubmitting}
            >
              <img src={Submitbtn} alt="Submit" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Vrsscoreboarddata;
