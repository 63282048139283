import React from "react";
import Styles from "../../Styles/dashboard.module.scss";
import headLogo from "../../Assets/Logo/headlogo.webp";

function Dashboard() {
  return (
    <div className={Styles.dashboarddatahomecontainer}>
      <div className={Styles.dashboardbtnparent}>
        <div className={Styles.dashboardbtnchildone}>
          <button className={Styles.dashboardbtn}>FAST REACTION </button>
          <button className={Styles.dashboardbtn}>GR SIMULATOR</button>
          <button className={Styles.dashboardbtn}>KINETIC PLATFORM</button>
        </div>

        <div className={Styles.dashboardbtnchildtwo}>
          <button className={Styles.dashboardbtn}>VR DRONE </button>
          <button className={Styles.dashboardbtn}>VR SIMULATOR</button>
          <button className={Styles.dashboardbtn}>EXPORT DATA</button>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
