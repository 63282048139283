import React, { useRef, useEffect, useState } from "react";
import Styles from "../../Styles/reg.module.scss";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Headlogo from "../../Assets/Logo/headlogo.webp";
import Tcselector from "../../Assets/Registration/TC.png";
import Tcbox from "../../Assets/Registration/Box.png";
import Tcboxh from "../../Assets/Registration/BoxH.png";
import Footerlogo from "../../Assets/Logo/footerlogo.webp";

function Registration() {
  const [name, SetName] = useState("");
  const [email, SetEmail] = useState("");
  const [phone, SetPhone] = useState("");
  const [gender, setGender] = useState(""); // Added gender state
  const [MaleSelected, setMaleSelected] = useState(false);
  const [FemaleSelected, setFemaleSelected] = useState(false);
  const [BtnSelected, setBtnSelected] = useState(false);

  const [TcboxSelected, setTcboxSelected] = useState(false);

  const [ButtonState, setButtonState] = useState(false);

  const form = useRef();
  const navigate = useNavigate();

  const Popup = () => {
    Swal.fire({
      title: "Disclaimer",
      html: `
        <div style="color: white;">
          <div class="arabicprivacy">
         
            <p>سيتم استخدام بياناتك الشخصية لإنشاء رمز خاص للمشاركة في منافسة تسجيل النقاط</p>
            <br />
            <p>بإرسال هذا النموذج أؤكد موافقتي على تلقي اتصالات تسويقية مباشرة من قسم مبيعات تويوتا عبر البريد الالكتروني ومواقع التواصل الاجتماعية والواتساب والمكالمات والرسائل النصية</p>
          </div>
          <br />
          <div class="englishprivacy">
            <p>Your personal data will be used to generate a unique code for participating in the scoring competition winner.</p>
            <br />
            <p>By submitting this form, I confirm that I consent to receive direct marketing communications from Toyota through Email, Social sites, WhatsApp, phone calls & SMS.</p>
          </div>
        </div>
      `,
      showCloseButton: true,
      confirmButtonText: "Close",
      customClass: {
        popup: "transparent-container",
        closeButton: "red-close-btn",
        confirmButton: "red-ok-btn",
      },
    });

    // Adding custom CSS for Swal styling
    const style = document.createElement("style");
    style.innerHTML = `
      .transparent-container {
        background: rgba(0, 0, 0, 0.9) !important; /* Transparent background */
        color: white; /* White text color */
      }
      .red-close-btn {
        color: red !important; /* Red close button */
      }
      .red-ok-btn {
        background-color: red !important; /* Red confirm button */
        color: white !important; /* White text for confirm button */
      }
    `;
    document.head.appendChild(style);
  };

  const isEnglish = (text) =>
    /^[A-Za-z0-9@.,;!#$%&'()*+\/=?^_`{|}~\s-]*$/.test(text);

  const Malehandleselector = () => {
    setMaleSelected(true);
    setFemaleSelected(false);
  };

  const Submithandleselector = () => {
    setBtnSelected(true);
  };

  const Tcboxhandleselector = () => {
    Popup();
    setTcboxSelected((pre) => {
      return !pre;
    });
  };

  const Femalehandleselector = () => {
    setMaleSelected(false);
    setFemaleSelected(true);
  };

  const validateEnglishInput = (e, setState) => {
    const value = e.target.value;
    if (!isEnglish(value)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Type Only in English</div>',
        timer: 2000,
      });
    } else {
      setState(value);
    }
  };

  const handleSubmit = (e) => {
    setTimeout(() => {
      setBtnSelected(false);
    }, 1000);

    e.preventDefault();
    if (!name.trim() || !email.trim() || !TcboxSelected || !phone.trim()) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `
         
            <div>Please Fill All the Fields</div>
          
        `,
        timer: 4000,
      });

      return;
    }

    if (!isEnglish(name) || !isEnglish(email)) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Please Fill in English Only</div>',
        timer: 4000,
      });
    } else if (name.trim() === "") {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Name Cannot Be Empty</div>',
        timer: 4000,
      });
    } else if (email.length < 5) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;">Email Should Be at Least 5 Characters Long</div>',
        timer: 4000,
      });
    } else if (email.split("").filter((x) => x === "@").length !== 1) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;">Email Should Contain  @</div>',
        timer: 4000,
      });
    } else if (email.indexOf(".") === -1) {
      Swal.fire({
        title: "Oops...",
        icon: "error",
        showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: '<div style="color: white;"> Email Should Contain at Least One Dot</div>',
        timer: 4000,
      });
    } else {
      setButtonState(true);
      Submithandleselector(true);
      Swal.fire({
        title: "",
        showCloseButton: false,
        showConfirmButton: false,
        customClass: {
          popup: "custom-swal",
          htmlContainer: "custom-html",
          title: "swal2-title",
        },
        backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
        html: `
          <div style="text-align: center;">
            <div class="loader"></div>
            <div style="color: white;">Submitting Your Data...</div>
          </div>
        `,
        timer: 4000, // Optional timer or you can remove this line
      });
      const API = process.env.REACT_APP_API_URL;

      axios
        .post(`${API}/user`, { name, email, phone, gender })

        .then((response) => {
          setButtonState(false);
          Submithandleselector(false);

          if (!response.data.err) {
            Swal.fire({
              title: "Registration Successful!",
              icon: "success",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              html: '<div style="color: white;">Please Check Your Registered Email</div>',
              showConfirmButton: false,
              timer: 1500,
            });

            navigate("/viewuserid", { state: { user: response.data.results } });
          } else {
            setButtonState(false);
            Submithandleselector(false);
            Swal.fire({
              title: "Oops...",
              icon: "error",
              showCloseButton: true,
              showConfirmButton: false,
              customClass: {
                popup: "custom-swal",
                htmlContainer: "custom-html",
                title: "swal2-title",
              },
              backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
              html: '<div style="color: white;"> Registartion Failed</div>',
              showConfirmButton: false,
              timer: 1500,
            });
            console.log("Some error occurred");
          }
        })
        .catch((err) => {
          setButtonState(false);
          Submithandleselector(false);
          let errorMessage = "Something Went Wrong!";
          if (err.response) {
            if (err.response.data && err.response.data.message) {
              if (err.response.data.message === "Email already registered") {
                errorMessage = "Email already registered";
              } else {
                errorMessage = err.response.data.message;
              }
            } else {
              errorMessage = `Error: ${err.response.status}`;
            }
          } else if (err.request) {
            errorMessage =
              "Oops! We couldn't reach the server. Please check your internet connection and try again.";
            console.log(err.request);
          } else {
            errorMessage = err.message;
          }
          Swal.fire({
            title: "Oops...",
            icon: "error",
            showCloseButton: true,
            showConfirmButton: false,
            customClass: {
              popup: "custom-swal",
              htmlContainer: "custom-html",
              title: "swal2-title",
            },
            backdrop: `rgba(0,0,0,0.1) left top no-repeat`,
            html: `<div style="color: white;">${errorMessage}</div>`,
            showConfirmButton: false,
            timer: 4000,
          });
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={Styles.contactcolorbg}>
      <div className={Styles.contactsection}>
        <div className={Styles.contactimgmain}>
          <img src={Headlogo} alt="headimg" />
        </div>
        <form
          ref={form}
          className={Styles.contactform}
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div className={Styles.contactforminputmain}>
            <input
              className={Styles.inputNameEmail}
              type="text"
              id="user_name"
              placeholder="NAME & FAMILY NAME"
              onChange={(e) => validateEnglishInput(e, SetName)}
              maxLength={50}
              minLength={1}
              autoComplete="off"
              name="user_name"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <input
              className={Styles.inputNameEmail}
              type="number"
              id="user_phone"
              placeholder="MOBILE NUMBER"
              onChange={(e) => validateEnglishInput(e, SetPhone)}
              maxLength={30}
              minLength={1}
              autoComplete="off"
              name="user_phone"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <input
              className={Styles.inputNameEmail}
              type="email"
              id="user_email"
              placeholder="EMAIL"
              autoComplete="off"
              onChange={(e) => validateEnglishInput(e, SetEmail)}
              name="user_email"
            />
          </div>

          <div className={Styles.contactforminputmain}>
            <input
              type="radio"
              style={{ visibility: "hidden" }}
              className={Styles.inputGenderboxone}
              onClick={Malehandleselector}
              id="Male"
              name="gender"
              value="Male"
              onChange={(e) => setGender(e.target.value)}
            />
            <label
              htmlFor="Male"
              className={
                MaleSelected ? Styles.labelMaleSelected : Styles.labelMale
              }
            ></label>

            <input
              type="radio"
              style={{ visibility: "hidden" }}
              className={Styles.inputGenderboxtwo}
              id="Female"
              onClick={Femalehandleselector}
              name="gender"
              value="Female"
              onChange={(e) => setGender(e.target.value)}
            />
            <label
              htmlFor="Female"
              className={
                FemaleSelected ? Styles.labelFemaleSelected : Styles.labelFemale
              }
            ></label>
          </div>
          <div onClick={Tcboxhandleselector} className={Styles.tcselector}>
            <span className={Styles.tcboxcontainer}>
              <img src={TcboxSelected ? Tcboxh : Tcbox} alt="" />
            </span>
            <a>
              <img src={Tcselector} />
            </a>
          </div>
          <br />
          <div className={Styles.contactforminputmain}>
            <button
              disabled={ButtonState}
              onClick={Submithandleselector}
              className={
                BtnSelected ? Styles.submitbtnselected : Styles.submitbtn
              }
              type="submit"
            ></button>
          </div>
        </form>
        <div className={Styles.footerlogo}>
          <img src={Footerlogo} alt="Footerlogo" />
        </div>
      </div>
    </div>
  );
}

export default Registration;
