import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "./axios";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./Components/Loading";

//All Routes
import AdminLogin from "./Components/Admin/Login";
import ExportData from "./Components/Export/Export";
import FastReactionStickLBoard from "./Components/FastReactionSticks/Frsl";
import FastReactionStickData from "./Components/FastReactionSticks/Frsldata";
import GrSimulatorLBoard from "./Components/GrSimulator/Grsl";
import GrSimulatorData from "./Components/GrSimulator/Grsldata";
import KineticPlatformLBoard from "./Components/KineticPlatform/Kpl";
import KineticPlatformData from "./Components/KineticPlatform/Kpldata";
import MainLBoard from "./Components/MainBoard/Ml";
import UserRegistration from "./Components/Registration/Reg";
import ViewUserId from "./Components/View/Viewid";
import ViewUserEmail from "./Components/View/Viewmail";
import VrGunGameLBoard from "./Components/VrGunGame/Vggl";
import VrGunGameData from "./Components/VrGunGame/Vggldata";
import VrSimulatorsLBoard from "./Components/VrSimulators/Vrsl";
import VrSimulatorsData from "./Components/VrSimulators/Vrsldata";
import Dashboard from "./Components/Dashboard/Dashboard";
import UnprotectedRoute from "./UnProtectedRoute";
import PrivateRoute from "./PrivateRoute";

function App() {
  const { refresh } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    setCheckingAuth(true);

    axios
      .get("/admin/profile")
      .then((response) => {
        dispatch({
          type: "accesToken",
          payload: { success: true, results: response.data.results },
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setCheckingAuth(false));
  }, [refresh, dispatch]);

  if (checkingAuth) {
    return <Loading content={"Loading..."} />;
  }
  return (
    <Router>
      <div className="mainhomecontainer">
        <Routes>
          <Route path="" element={<UnprotectedRoute />}>
            <Route path="/" element={<UserRegistration />} />
            <Route path="/frsl" element={<FastReactionStickLBoard />} />
            <Route path="/grsl" element={<GrSimulatorLBoard />} />
            <Route path="/kpl" element={<KineticPlatformLBoard />} />
            <Route path="/vrggl" element={<VrGunGameLBoard />} />
            <Route path="/vrsl" element={<VrSimulatorsLBoard />} />
            <Route path="/ml" element={<MainLBoard />} />
            <Route path="/viewuserid" element={<ViewUserId />} />
            <Route path="/viewscorecard/:id" element={<ViewUserEmail />} />
            <Route path="/login" element={<AdminLogin />} />
          </Route>
          <Route path="" element={<PrivateRoute />}>
            <Route path="/dh" element={<Dashboard />} />
            <Route path="/exportdata" element={<ExportData />} />
            <Route path="/frsldata" element={<FastReactionStickData />} />
            <Route path="/grsldata" element={<GrSimulatorData />} />
            <Route path="/kpldata" element={<KineticPlatformData />} />
            <Route path="/vrggldata" element={<VrGunGameData />} />
            <Route path="/vrsldata" element={<VrSimulatorsData />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
