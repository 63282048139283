import React, { useState, useEffect } from "react";
import Axios from "axios";
import Styles from "../../Styles/leaderboard.module.scss";
import Headlogo from "../../Assets/Logo/footerlogo.webp";
import VideoUrl from "../../Assets/Leaderboard/BgL.mp4";
//All Scoreboard Data

const Mainleaderboard = () => {
  //Get data from server
  const [data, setData] = useState([]);
  const Api = `${process.env.REACT_APP_API_URL}/scoreboard/over-all`;
  const getdata = async () => {
    try {
      let response = await Axios.get(Api);
      setData(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getdata();
    const pollInterval = setInterval(() => {
      getdata();
    }, 5000);

    return () => {
      clearInterval(pollInterval);
    };
  }, []);

  const sortedData = [...data].sort((a, b) => {
    return Number(b.scorevalue) - Number(a.scorevalue);
  });

  const latestData = sortedData.slice(0, 10);

  console.log(latestData);

  return (
    <div className={Styles.Allleadermaincontainer}>
      <video
        className={Styles.videocontainer}
        controls={false}
        width="100%"
        src={VideoUrl}
        poster=""
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        controlsList="nodownload"
      />
      <div className={Styles.Allleaderbgstick}>
        <div className={Styles.leaderimgmain}>
          <img src={Headlogo} alt="headimg" />
        </div>
        <div className={Styles.Allleaderlogocontainer}>
          <h1>
            <span id={Styles.arabictext}>قائمة المتصدرين</span> <br />
            OVERALL LEADERBOARD
          </h1>
        </div>

        <div className={Styles.Allleadersubheadingcontainer}>
          <div>
            {/* <p className="arabictext"> اسم</p> */}
            <p>NAME</p>
          </div>

          <div>
            {/* <p className="arabictext"> نتيجة</p> */}
            <p>SCORE</p>
          </div>
        </div>

        <div className={Styles.Allmainscoreboardcontainer}>
          {latestData.map((item, index) => (
            <div key={item.user.code} className={Styles.Allmainscoreboardrow}>
              <div className={Styles.Allcontainerone}>{index + 1}</div>
              <div className={Styles.Allseparator}></div>
              <div className={Styles.Allcontainertwo}>{item.user.name}</div>
              <div className={Styles.Allseparator}></div>
              <div className={Styles.Allcontainerthree}>{item.totalScore}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mainleaderboard;
