import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Styles from "../../Styles/viewemail.module.scss";
import Headlogo from "../../Assets/Logo/headlogo.webp";
import Footerlogo from "../../Assets/Logo/footerlogo.webp";
import Game from "../../Assets/Titlecards/Game.png";
import Loading from "../Loading";

const Viewmailid = () => {
  const { id } = useParams();

  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const API = process.env.REACT_APP_API_URL;

      try {
        const response = await axios.get(`${API}/scoreboard/user/${id}`);
        if (response.status === 200) {
          setUserData(response.data.results);
        } else {
          console.error("Failed to fetch user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchUserData();
    }
  }, [id]);

  if (!id) {
    return <Loading content={"No unique code found in URL"} />;
  }

  if (loading) {
    return <Loading content={"Loading..."} />;
  }

  if (!userData) {
    return <Loading content={"Error fetching user data"} />;
  }

  return (
    <div className={Styles.scoreboardcontactcolorbg}>
      <div key={userData[0]._id} className={Styles.scoreboardcontactsection}>
        <div className={Styles.scoreboardcontactimgmain}>
          <img src={Headlogo} alt="headimg" />
        </div>
        <div className={Styles.scorecardparent}>
          <div className={Styles.scorecardname}>
            {userData[0].user.name
              .split("\n")
              .slice(0, 2)
              .map((para, index) => (
                <h1 key={index}>
                  {para.length > 20 ? `${para.slice(0, 20)}...` : para}
                </h1>
              ))}

            <h2>YOUR SCORE CARD</h2>
          </div>

          {userData.map((gameData) => (
            <div key={gameData._id} className={Styles.scoreboardinputcontainer}>
              <div className={Styles.scoreboardinput}>
                <span>{gameData.score}</span>{" "}
                <img src={Game} alt={`Game ${gameData.gameNumber}`} />
                <h1>{gameData.game.name}</h1>
              </div>
            </div>
          ))}
        </div>

        <div className={Styles.scoreboardfooterlogo}>
          <img src={Footerlogo} alt="Footerlogo" />
        </div>
      </div>
    </div>
  );
};

export default Viewmailid;
